import { getCookies, setCookie, getCookie, type OptionsType, type CookiesFn } from 'cookies-next'
import { constants } from 'helpers'


const isClientSide = (options?: OptionsType) => {
  return !options?.req && !options?.res && !(options && 'cookies' in options && (options?.cookies as CookiesFn))
}

const validateServerOptions = (options?: OptionsType): void => {
  if (__SERVER__ && isClientSide(options)) {
    throw new Error('Request should be defined')
  }
}

const setItem = (key: string, data: any, options: OptionsType = {}): void => {
  const finalOptions: OptionsType = {
    maxAge: 365 * 24 * 60 * 60, // 1 year
    httpOnly: false,
    sameSite: 'lax',
    path: '/',
    domain: constants.baseUrl.replace('https://', ''),
    ...options,
  }

  validateServerOptions(options)
  setCookie(key, data, finalOptions)
}

const setSessionItem = (key: string, data: any, options: OptionsType = {}): void => {
  setItem(key, data, { ...options, maxAge: undefined })
}

const getItem = (key: string, options: OptionsType = {}) => {
  validateServerOptions(options)

  return getCookie(key, options)
}

const getAllItems = (options?: OptionsType) => {
  validateServerOptions(options)

  return getCookies(options)
}

const removeItem = (key: string, options?: OptionsType): void => {
  return setItem(key, '', { ...options, maxAge: -1 })
}

export default {
  setItem,
  setSessionItem,
  getItem,
  getAllItems,
  removeItem,
}
